import Modal from 'bootstrap/js/dist/modal';

const partnerLandingForm = document.getElementById('partner-landing-direct-form');

if (partnerLandingForm) {
  const inputEmail = partnerLandingForm.querySelector('#partner-landing-direct-email');
  const inputPostcode = partnerLandingForm.querySelector('#partner-landing-direct-postcode');
  const submit = partnerLandingForm.querySelector('#partner-landing-direct-submit');
  const notice = partnerLandingForm.querySelector('#partner-landing-direct-notice');
  const modal = document.querySelector('#landing-modal');
  const bootstrapModal = new Modal(modal);
  const modalEmailInput = modal.querySelector('[type="email"]');
  const modalZipInput = modal.querySelector('.landing-zip input');
  const appUrl = window.location === 'enerix-staging.lab.inspeerity.com' ? 'enerix-webapp-dev.lab.inspeerity.com' : 'my.enerix.de';

  if (inputEmail && inputPostcode && submit) {
    submit.addEventListener('click', () => {
      
      const email = inputEmail.value;
      const postcode = inputPostcode.value;
      const nonce = inputPostcode.dataset.nonce;

      if (!email || !postcode) {

        if ( !email || ! /(.+)@(.+){2,}\.(.+){2,}/.test(email) )  {
          inputEmail.classList.add('is-invalid');
        }

        if ( ! postcode )  {
          inputPostcode.classList.add('is-invalid');
        }
        
        return;
      }


      inputEmail.classList.remove('is-invalid');
      inputPostcode.classList.remove('is-invalid');

      partnerLandingForm.classList.add('loading');

      if (postcode) {
        jQuery.ajax({
          type: 'post',
          dataType: 'json',
          url: enerixAjax.ajaxurl,
          data: {
            action: 'get_single_partner_by_postcode',
            postcode: postcode,
            nonce: nonce,
          },
          success: function (response) {
            partnerLandingForm.classList.remove(
              'e-partner-landing-form--error'
            );
            notice.innerHTML = '';
            if (response.status == 'success') {
              var url = new URL(window.location.href);
              url.searchParams.set('countryCode', response.data.location_country_code);
              url.searchParams.set('postCode', response.data.request_postcode);
              url.searchParams.set('email', email);

              partnerLandingForm.classList.remove('loading');
              window.location = 'https://' + appUrl + '/new-user/direct-flow' + url.search;
              
            } else {
              partnerLandingForm.classList.add('e-partner-landing-form--error');
              notice.innerHTML =
                '<p class="text-danger">Die enerix WebApp ist bald auch in deiner Region verfügbar.</p>';
              bootstrapModal.show();
              partnerLandingForm.classList.remove('loading');
            }
          },
        });
      }
    });

    inputEmail.onkeydown = function (e) {
      inputEmail.classList.remove('is-invalid');

      if (modalEmailInput) {
        setTimeout(() => modalEmailInput.value = inputEmail.value, 500);
      }

      if (e.key == "Enter") {
        submit.click()
      }
    };

    inputPostcode.onkeydown = function (e) {
      inputPostcode.classList.remove('is-invalid');

      if (modalZipInput) {
        setTimeout(() => modalZipInput.value = inputPostcode.value, 500);
      }

      if (e.key == "Enter") {
        submit.click()
      }
    };
  }
}