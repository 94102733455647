import Modal from 'bootstrap/js/dist/modal';

const partnerLandingForm = document.getElementById('partner-landing-form');

if (partnerLandingForm) {
  const inputEmail = partnerLandingForm.querySelector('#partner-landing-email');
  const inputPostcode = partnerLandingForm.querySelector('#partner-landing-postcode');
  const submit = partnerLandingForm.querySelector('#partner-landing-search');
  const notice = partnerLandingForm.querySelector('#partner-landing-notice');
  const result = document.querySelector('.e-partner-landing-form-result');
  const modal = document.querySelector('#landing-modal');
  const bootstrapModal = new Modal(modal);
  const modalEmailInput = modal.querySelector('[type="email"]');
  const modalZipInput = modal.querySelector('.landing-zip input');
  const appUrl = window.location === 'enerix-staging.lab.inspeerity.com' ? 'enerix-webapp-dev.lab.inspeerity.com' : 'my.enerix.de';

  if (inputEmail && inputPostcode && submit) {
    submit.addEventListener('click', () => {

      const email = inputEmail.value;
      const postcode = inputPostcode.value;
      const nonce = inputPostcode.dataset.nonce;

      if (!email || !postcode || ! /(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
        
        if (!email || ! /(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
          inputEmail.classList.add('is-invalid');
        }

        if (!postcode) {
          inputPostcode.classList.add('is-invalid');
        }
        
        return;
      }
      


      inputEmail.classList.remove('is-invalid');
      inputPostcode.classList.remove('is-invalid');

      if (postcode && email) {
        partnerLandingForm.classList.add('loading');

        jQuery.ajax({
          type: 'post',
          dataType: 'json',
          url: enerixAjax.ajaxurl,
          data: {
            action: 'get_single_partner_by_postcode',
            postcode: postcode,
            nonce: nonce,
          },
          success: function (response) {
            partnerLandingForm.classList.remove(
              'e-partner-landing-form--error'
            );
            notice.innerHTML = '';
            result.innerHTML = '';
            
            if (response.status == 'success') {
              var url = new URL(window.location.href);
              url.searchParams.set('countryCode', response.data.location_country_code);
              url.searchParams.set('postCode', response.data.request_postcode);
              url.searchParams.set('email', email);
              let partnerHtml = `
              <h4 class="mt-7 mb-2">Dein regionaler enerix Partner</h4>
              <div class="e-partner-landing__partner-result p-3 px-md-6 py-md-4 bg-white rounded-4">
                <div class="row gy-5 gx-4 align-items-center">
                  <div class="col-md-6">
                    <div class="text-dark m-0 d-flex flex-column flex-md-row">
                        <div class="d-flex flex-column flex-lg-row align-items-center mt-1 mt-md-0">
                            <img src="${response.data.location_thumbnail}" class="e-partner-landing__partner-result-image rounded-circle object-fit-cover mb-2 mb-lg-0">
                            <div class="ms-0 ms-md-2">
                                <h3 class="fs-5 fs-sm-5 fw-600 mb-1 text-center text-lg-start">${response.data.location_head.team_member_name}</h3>
                                <p class="mb-2 text-center text-lg-start">
                                  ${response.data.location_head.team_member_position}<br>
                                  ${response.data.location_title}
                                </p>
                                <div class="d-flex flex-column flex-md-row flex-wrap">
                                  <a href="tel:${response.data.location_phone}" class="text-decoration-none d-inline-flex g-2 align-items-center text-primary me-2">
                                    <span class="e-icon e-icon--size-normal e-icon--phone"></span>
                                    <span class="d-block ms-1">${response.data.location_phone}</span>
                                  </a>
                                  <a href="email:${response.data.location_mail}" class="text-decoration-none d-inline-flex g-2 align-items-center text-primary">
                                    <span class="e-icon e-icon--size-normal e-icon--mail"></span>
                                    <span class="d-block ms-1">${response.data.location_mail}</span>
                                  </a>
                                </div>                          
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6 text-center text-md-end">
                    <h3 class="fs-5 fs-sm-5 fw-600 mb-1">Vor-Ort Beratung</h3>
                    <p class="mb-2">Direkt mit einem lokalen enerix Solarexperten in Kontakt treten um Dein Vorhaben zu besprechen.</p>
                    <a class="btn btn-primary" href="https://${appUrl}/new-user/meeting-flow${url.search}" target="_blank">Telefonat vereinbaren</a>
                  </div>
                </div>
              </div>
              `;
              result.innerHTML = partnerHtml;
              partnerLandingForm.classList.remove('loading');
            } else {
              partnerLandingForm.classList.add('e-partner-landing-form--error');
              notice.innerHTML =
                '<p class="text-danger">Die enerix WebApp ist bald auch in deiner Region verfügbar.</p>';
              bootstrapModal.show();
              partnerLandingForm.classList.remove('loading');
            }
          },
        });
      } else {
        notice.innerHTML = '';
        result.innerHTML = '';
      }
    });

    inputEmail.onkeydown = function (e) {
      inputEmail.classList.remove('is-invalid');
      
      if (modalEmailInput) {
        setTimeout(() => modalEmailInput.value = inputEmail.value, 500);
      }
      
      if (e.key == "Enter") {
        submit.click()
      }
    };

    inputPostcode.onkeydown = function (e) {
      inputPostcode.classList.remove('is-invalid');

      if (modalZipInput) {
        setTimeout(() => modalZipInput.value = inputPostcode.value, 500);
      }

      if (e.key == "Enter") {
        submit.click()
      }
    };

    
  }
}
