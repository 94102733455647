const partnerLocatorBlock = document.querySelector('.e-partners-locator');
const partnersMap = document.querySelector('.js-partners-map');
const partnerLocatorInput = document.getElementById('partners-locator-input');
const partnerLocatorGeolocate = document.getElementById('button-map-geolocate');
const partnerLocatorButton = document.getElementById('button-map-search');
const partnerLocatorEmptyList = document.querySelector('.js-partners-locator-nearest-list');

let map;


window.initMaps = function () {
    if (partnersMap) {
        map = initMap(partnersMap);
    }

    if (partnerLocatorInput) {
        initPartnerLocator(partnerLocatorInput, map);
    }
};

const geolocateOptions = {
    maximumAge: 60000,
    timeout: 5000,
    enableHighAccuracy: true
};

if (partnerLocatorGeolocate) {
    partnerLocatorGeolocate.addEventListener('click', () => {
        partnerLocatorBlock.classList.remove('e-partners-locator--nearest');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos) {
                    //You have your locaton here
                    fetch(
                        'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                        pos.coords.latitude +
                        ',' +
                        pos.coords.longitude +
                        '&location_type=ROOFTOP&key=AIzaSyBExjOtwNRpPAQ7fyMOijvRRHdheS7A_JU'
                    )
                        .then((response) => response.json())
                        .then((json) => {
                            if (json.results) {
                                let address = json.results[0];
                                let postcode = '';
                                if (address.address_components) {
                                    address.address_components.forEach((component) => {
                                        if (component.types[0] == 'postal_code') {
                                            postcode = component.long_name;
                                        }
                                    });
                                }

                                if (postcode) {
                                    showPartnerForPostcode(postcode);
                                } else {
                                    showNearestPartners(pos.coords.latitude, pos.coords.longitude);
                                }
                            }
                        });
                },
                function () {
                    console.log('Geolocate error!')
                },
                geolocateOptions
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    });
}

function initPartnerLocator(input, map) {
    if (input) {
        // Create a bounding box with sides ~10km away from the center point
        const options = {
            componentRestrictions: { country: ['de', 'at'] },
            strictBounds: false,
        };
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            let postcode = '';

            partnerLocatorBlock.classList.remove('e-partners-locator--nearest');
            partnerLocatorBlock.classList.add('loading');

            if (place.address_components) {
                place.address_components.forEach((component) => {
                    if (component.types[0] == 'postal_code') {
                        postcode = component.long_name;
                    }
                });
            }

            if (!postcode) {
                fetch(
                    'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                    lat +
                    ',' +
                    lng +
                    '&key=AIzaSyBExjOtwNRpPAQ7fyMOijvRRHdheS7A_JU'
                )
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.results) {
                            let postcode_alt = ''
                            let address = json.results[0];
                            if (address.address_components) {
                                address.address_components.forEach((component) => {
                                    if (component.types[0] == 'postal_code') {
                                        postcode_alt = component.long_name;
                                    }
                                });
                            }
                            if (postcode_alt) {
                                showPartnerForPostcode(postcode_alt, place);
                            } else {
                                partnerLocatorBlock.classList.remove('loading');
                                showNearestPartners(lat, lng);
                            }
                        }
                    });
            } else {
                showPartnerForPostcode(postcode, place);
            }
        });
    }

    if (partnerLocatorButton) {
        partnerLocatorButton.addEventListener('click', () => {
            google.maps.event.trigger(input, 'focus', {});
        });
    }
}

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function showNearestPartners(lat, lng) {
    var bounds = new google.maps.LatLngBounds();
    const location = new google.maps.LatLng(lat, lng);
    const distances = [];

    for (var i = 0; i < map.markers.length; i++) {
        var markerLocation = new google.maps.LatLng(map.markers[i].position.lat(), map.markers[i].position.lng());
        distances.push({
            id: i,
            distance: google.maps.geometry.spherical.computeDistanceBetween(location, markerLocation)
        });
    }

    distances.sort(dynamicSort('distance'));

    partnerLocatorEmptyList.innerHTML = '';
    partnerLocatorBlock.classList.add('e-partners-locator--nearest');

    for (var i = 0; i < 3; i++) {

        bounds.extend({
            lat: map.markers[distances[i]['id']].position.lat(),
            lng: map.markers[distances[i]['id']].position.lng(),
        });

        partnerLocatorEmptyList.innerHTML = partnerLocatorEmptyList.innerHTML + map.markers[distances[i]['id']].content;

        map.fitBounds(bounds);
    }
}

function showPartnerForPostcode(postcode, place) {
    let nonce = partnerLocatorBlock.dataset.nonce;

    jQuery.ajax({
        type: 'post',
        dataType: 'json',
        url: enerixAjax.ajaxurl,
        data: {
            action: 'get_partner_by_postcode',
            postcode: postcode,
            nonce: nonce,
        },
        success: function (response) {
            partnerLocatorBlock.classList.remove('loading');
            if (response.length) {
                centerOnPartner(map, response[0].location_id);
            } else {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                showNearestPartners(lat, lng);
            }
        },
    });
}



function initMap(el) {
    let markers = el.querySelectorAll('.marker');

    let mapArgs = {
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [

            // { "featureType": "all", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
            // { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] },
            // { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] },
            // { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "simplified" }] },
            // { "featureType": "poi.attraction", "elementType": "all", "stylers": [{ "visibility": "off" }] },
            // { "featureType": "poi.business", "elementType": "all", "stylers": [{ "visibility": "off" }] },
            // { "featureType": "poi.government", "elementType": "all", "stylers": [{ "visibility": "off" }] },
            // { "featureType": "poi.sports_complex", "elementType": "all", "stylers": [{ "visibility": "off" }] },


            /* Border */
            { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#0d7c3d" }, { "lightness": 0 }, { "weight": 1 }] },

            /* Landscape color - green */
            { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#c9df90" }, { "lightness": 0 }] },

            /* Set roads color to bg color */
            { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#9cc434" }, { "lightness": 17 }] },

            /* Change water color */
            { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c5f1fc" }, { "visibility": "on" }] },

            { "featureType": "administrative.locality", "elementType": "labels.text.stroke", "stylers": [{ "visibility": "off" }] },
            { "featureType": "administrative.country", "elementType": "labels.text.stroke", "stylers": [{ "visibility": "off" }] },
            { "featureType": "administrative.province", "elementType": "labels.text.stroke", "stylers": [{ "visibility": "off" }] },

        ],
    };
    let map = new google.maps.Map(el, mapArgs);

    map.markers = [];

    markers.forEach((marker) => {
        initMarker(marker, map);
    });
    centerMap(map);

    return map;
}




function initMarker(markerEl, map) {
    var lat = markerEl.getAttribute('data-lat');
    var lng = markerEl.getAttribute('data-lng');
    var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };

    const icon = {
        url: '/wp-content/themes/enerix/icons/map-pin.png',
        scaledSize: new google.maps.Size(20, 20), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    const iconActive = {
        url: '/wp-content/themes/enerix/icons/map-pin-active.png',
        scaledSize: new google.maps.Size(20, 20), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };


    var marker = new google.maps.Marker({
        partnerId: markerEl.dataset.partnerId,
        position: latLng,
        map: map,
        content: markerEl.innerHTML,
        icon: icon
    });

    if (markerEl.innerHTML) {
        var infowindow = new google.maps.InfoWindow({
            partnerId: markerEl.dataset.partnerId,
            content: markerEl.innerHTML,
        });

        marker.infowindow = infowindow;

        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
            marker.setIcon(iconActive);
        });

        google.maps.event.addListener(infowindow, 'closeclick', function () {
            marker.setIcon(icon);
        });
    }

    map.markers.push(marker);
}

function centerOnPartner(map, partnerId) {
    let iconCenterActive = {
        url: '/wp-content/themes/enerix/icons/map-pin-active.png',
        scaledSize: new google.maps.Size(20, 20), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 0) // anchor
    };

    map.markers.forEach(function (marker) {
        if (marker.partnerId == partnerId) {
            marker.setIcon(iconCenterActive);
            map.setCenter(marker.position);
            map.setZoom(12);
            marker.infowindow.open(map, marker);
        }
    });
}

function centerMap(map) {
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng(),
        });
    });

    if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());
    } else {
        map.fitBounds(bounds);
    }
}
